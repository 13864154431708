import React, { useMemo } from 'react'
// import React, { useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import TabFilter from '../../../common/TabFilter'
// import SearchInput from '../../../common/Input/SearchInput'
import { useAnalyticsVersion } from '../../../../hooks/useGeneral'
// import { AnalyticVersions, showV1Analytics } from '../../../../config/constants'

const Tabs = ['OVERVIEW', 'TOKENS', 'PAIRS']

const getSubRoute = (item) => {
  return item === Tabs[0] ? '' : item === Tabs[1] ? '/tokens' : '/pairs'
}

const AnalyticsHeader = ({ data }) => {
  // const [searchText, setSearchText] = useState('')
  const version = useAnalyticsVersion()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeTab = useMemo(() => {
    const arr = pathname.split('/')
    let result
    switch (arr[3]) {
      case 'pairs':
        result = Tabs[2]
        break
      case 'pair':
        result = Tabs[2]
        break
      case 'tokens':
        result = Tabs[1]
        break
      case 'token':
        result = Tabs[1]
        break

      default:
        result = Tabs[0]
        break
    }
    return result
  }, [pathname])

  // const v1Available = showV1Analytics

  return (
    <>
      <h1 className='text-[34px] md:text-[45px] font-semibold font-heading text-primaryText'>SwapX Analytics</h1>
      {/* <div className='flex justify-center mt-3 w-full'>
        <div className='flex h-11 justify-center w-full md:w-auto'>
          {v1Available ? (
            <>
              <Link className='w-full md:w-auto' to={`/analytics/${AnalyticVersions.v1}`}>
                <div
                  className={`md:w-[100px] h-full flex justify-center items-center cursor-pointer 
              ${
                version === AnalyticVersions.v1
                  ? 'text-white font-semibold border-themeOrange border rounded-[5px] -ml-px popup-gradientbg'
                  : 'text-[#A2A0B7] font-normal border-[#555367] border-l border-t border-b rounded-l-[5px] -mr-[3px] -ml-px'
              } `}
                >
                  V1
                </div>
              </Link>
              <Link className='w-full md:w-auto' to={`/analytics/${AnalyticVersions.fusion}`}>
                <div
                  className={`md:w-[100px] h-full flex justify-center items-center cursor-pointer 
              ${
                version === AnalyticVersions.fusion
                  ? 'text-white font-semibold border-themeOrange border rounded-[5px] -ml-px popup-gradientbg'
                  : 'text-[#A2A0B7] font-normal border-[#555367] border-t border-b -mr-[3px] -ml-[3px]'
              } `}
                >
                  FUSION
                </div>
              </Link>
              <Link className='w-full md:w-auto' to={`/analytics/${AnalyticVersions.total}`}>
                <div
                  className={`md:w-[100px] h-full flex justify-center items-center cursor-pointer
              ${
                version === AnalyticVersions.total
                  ? 'text-white font-semibold border-themeOrange border rounded-[5px] -mr-px popup-gradientbg'
                  : 'text-[#A2A0B7] font-normal border-[#555367] border-r border-t border-b rounded-r-[5px] -ml-[3px] -mr-px'
              }`}
                >
                  TOTAL
                </div>
              </Link>
            </>
          ) : (
            <Link className='w-full md:w-auto' to={`/analytics/${AnalyticVersions.fusion}`}>
              <div
                className={`md:w-[100px] h-full flex justify-center items-center cursor-pointer 
              ${
                version === AnalyticVersions.fusion
                  ? 'text-white font-semibold border-themeOrange border rounded-[5px] -ml-px popup-gradientbg'
                  : 'text-[#A2A0B7] font-normal border-[#555367] border-t border-b -mr-[3px] -ml-[3px]'
              } `}
              >
                FUSION
              </div>
            </Link>
          )}
        </div>
      </div> */}
      <div className='flex justify-center items-center mt-5 lg:my-8'>
        <TabFilter data={Tabs} filter={activeTab} setFilter={(item) => navigate(`/analytics/${version}${getSubRoute(item)}`)} />
      </div>
      {pathname.split('/')?.[3] !== 'pair' && (
        <div className='flex justify-center mt-5 lg:mt-8 w-full'>
          {data && (
            <p className='text-[15px] leading-[15px]'>
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate(`/analytics/${version}`)
                }}
              >
                Analytics
              </span>
              &nbsp;{'>'}&nbsp;
              <span
                className='cursor-pointer'
                onClick={() => {
                  navigate(`/analytics/${version}/${data.token0 ? 'pairs' : 'tokens'}`)
                }}
              >
                {data.token0 ? 'Pairs' : 'Tokens'}
              </span>
              &nbsp;{'>'}&nbsp;
              <span className='font-medium'>{data.token0 ? data.token0.symbol + '/' + data.token1.symbol : data.symbol}</span>&nbsp;
              <span>({data.id && data.id.slice(0, 6) + '...' + data.id.slice(38, 42)})</span>
            </p>
          )}
        </div>
      )}
    </>
  )
}

export default AnalyticsHeader
