import { extendTheme } from '@chakra-ui/react'

import { Button } from './button'

const bgColor = {
  dark: '#141915', //'gray.bg',
  light: '#e0e9dd', //'brandGray.bg',
}

const textColor = {
  dark: '#E0E8DD', //'brandGray.100',
  light: '#000', // 'gray.900',
}

const theme = extendTheme({
  components: {
    Button,
  },
  styles: {
    global: ({ colorMode }) => ({
      body: {
        backgroundColor: bgColor[colorMode] || bgColor.light,
        color: textColor[colorMode] || textColor.light,
      },
      //  Scrollbar Styles
      '::-webkit-scrollbar': {
        width: '10px',
      },
      '::-webkit-scrollbar-track': {
        background: colorMode === 'dark' ? '#252B28' : '#535f59',
      },
      '::-webkit-scrollbar-thumb': {
        background: colorMode === 'dark' ? '#666' : '#adb8b3',
        borderRadius: '10px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: colorMode === 'dark' ? '#777' : '#c8d0cc',
      },
    }),
  },
})

export default theme
