import { defineStyleConfig } from '@chakra-ui/react'

import { buttonLikeVariants } from './buttonLike'

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 'full',
    border: '1px solid',
    borderWidth: '1px',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      p: 3,
      borderWidth: '1px',
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
      borderWidth: '2px',
    },
    action: {
      fontSize: 'md',
      py: 4,
      h: '50px',
      w: '100%',
      textTransform: 'uppercase',
    },
  },
  variants: buttonLikeVariants.variants,
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
})
