import React from 'react'
import { useColorMode } from '@chakra-ui/react'

import { cn } from '../../../helpers/tailwind'
import { timeframeOptions } from '../../../config/constants'

const TimeframeFilter = ({ filter, setFilter, className }) => {
  const { colorMode } = useColorMode()

  return (
    <div className={cn('flex items-center gap-[5px]', className ? ' ' + className : '')}>
      {Object.values(timeframeOptions).map((i, k) => {
        return (
          <div
            key={k}
            className={cn(
              'rounded-[5px] border-[1px] border-transparent py-[3px] px-[5px] sm:text-[1rem] text-[0.9rem] cursor-pointer sm:font-medium transition-all duration-300 min-w-[36px] flex items-center justify-center ',
              i === filter &&
                (colorMode === 'dark' ? 'text-[#EAECE8] bg-[#7AF182] bg-opacity-30 border-[#7AF182]' : 'text-[#121212] bg-[#7AF182] border-[#7AF182]'),
            )}
            onClick={() => setFilter(i)}
          >
            {i}
          </div>
        )
      })}
    </div>
  )
}

export default TimeframeFilter
