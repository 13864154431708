import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { useColorMode } from '@chakra-ui/react'
import Pagination from '../../../common/Pagination'
import { NumberOfRows } from '../../../../config/constants'
// import { useBookmarkPairs } from '../../../../state/application/hooks'
import { formatAmount } from '../../../../utils/formatNumber'
import { useAnalyticsVersion } from '../../../../hooks/useGeneral'
import { cn } from '../../../../helpers/tailwind'

const SORT_FIELD = {
  NAME: 'name',
  LIQ: 'trackedReserveUSD',
  DAYVOL: 'oneDayVolumeUSD',
  WEEKVOL: 'oneWeekVolumeUSD',
  FEES: 'oneDayFeesUSD',
}

const HeaderOptions = [
  {
    label: 'Name',
    sort: SORT_FIELD.NAME,
  },
  {
    label: 'Liquidity',
    sort: SORT_FIELD.LIQ,
  },
  {
    label: '24H Volume',
    sort: SORT_FIELD.DAYVOL,
  },
  {
    label: '7D Volume',
    sort: SORT_FIELD.WEEKVOL,
  },
  {
    label: '24H Fees',
    sort: SORT_FIELD.FEES,
  },
]

const PairsTable = ({ pairsData, searchText }) => {
  // const { bookmarkPairs, addBookmarkPair, removeBookmarkPair } = useBookmarkPairs()
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.LIQ)
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const version = useAnalyticsVersion()
  const navigate = useNavigate()
  const { colorMode } = useColorMode()

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  const filteredPairs = useMemo(() => {
    if (!searchText) return pairsData
    return pairsData.filter((item) => {
      const withSpace = item.token0.symbol.replace('/', ' ') + '/' + item.token1.symbol.replace('/', ' ')
      const withComma = item.token0.symbol.replace('/', ',') + '/' + item.token1.symbol.replace('/', ',')
      const symbols = item.token0.symbol.toLowerCase() + item.token1.symbol.toLowerCase()
      return (
        item.token0.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
        item.token1.symbol.toLowerCase().includes(searchText.toLowerCase()) ||
        withSpace.toLowerCase().includes(searchText.toLowerCase()) ||
        withComma.toLowerCase().includes(searchText.toLowerCase()) ||
        symbols.includes(searchText.toLowerCase())
      )
    })
  }, [pairsData, searchText])

  const pageCount = useMemo(() => {
    return Math.ceil(filteredPairs.length / pageSize)
  }, [filteredPairs, pageSize])

  const sortedList = useMemo(() => {
    return (
      filteredPairs &&
      filteredPairs
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn]) ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
        })
        .slice(pageSize * currentPage, (currentPage + 1) * pageSize)
    )
  }, [filteredPairs, pageSize, currentPage, sortDirection, sortedColumn])

  const renderData = (data) => {
    return (
      <>
        <div className='w-1/2 lg:w-[15%] mt-[11px] lg:mt-0'>
          <p className='lg:hidden text-sm font-semibold'>{HeaderOptions[1].label}</p>
          <div className='leading-5 '>${formatAmount(data.trackedReserveUSD)}</div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[11px] lg:mt-0'>
          <p className='lg:hidden text-sm font-semibold'>{HeaderOptions[2].label}</p>
          <div className='leading-5 '>${formatAmount(data.oneDayVolumeUSD)}</div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
          <p className='lg:hidden text-sm font-semibold'>{HeaderOptions[3].label}</p>
          <div className='leading-5'>${formatAmount(data.oneWeekVolumeUSD)}</div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
          <p className='lg:hidden text-sm font-semibold'>{HeaderOptions[4].label}</p>
          <div className='leading-5'>${formatAmount(data.oneDayFeesUSD)}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={cn(
          'w-full rounded-[15px] lg:px-6 lg:pb-6 lg:pt-4 lg:mt-3.5 ',
          sortedList.length > 0 ? 'lg:bg-background' : 'bg-background pb-4 pt-4 mt-3.5 ',
          colorMode === 'dark' ? '' : `${sortedList.length > 0 ? 'lg-card-shadow' : ' card-shadow'}`,
        )}
      >
        <div className='lg:flex justify-between hidden font-medium text-[17px] xl:text-[18px] pb-3.5 border-b border-primaryBorder'>
          {HeaderOptions.map((option, idx) => {
            return (
              <div key={idx} className={`${idx === 0 ? 'w-[30%]' : 'w-[15%]'} flex items-center`}>
                <button
                  onClick={() => {
                    setSortedColumn(option.sort)
                    setSortDirection(sortedColumn !== option.sort ? true : !sortDirection)
                  }}
                  className={`flex items-center space-x-[3px]`}
                >
                  <span>{option.label}</span>
                  {sortedColumn === option.sort && (
                    <div className='flex items-center mt-[2px]'>
                      <svg
                        className={`mr-[3px] text-primaryText ${!sortDirection ? 'rotate-180' : ''} `}
                        xmlns='http://www.w3.org/2000/svg'
                        width={12}
                        viewBox='0 0 10 6'
                      >
                        <path
                          d='M9.37495 4.22194e-07L0.625082 -3.42744e-07C0.455805 -3.57543e-07 0.309275 0.0659276 0.185627 0.197893C0.0620475 0.329822 4.82045e-07 0.48605 4.66259e-07 0.666614C4.50474e-07 0.847177 0.0620131 1.00333 0.185627 1.13544L4.56058 5.80211C4.68419 5.93389 4.83062 6 5 6C5.16938 6 5.31581 5.93389 5.43952 5.80211L9.81448 1.13544C9.93819 1.00348 10 0.847178 10 0.666615C10 0.486087 9.93819 0.329823 9.81448 0.197894C9.69062 0.0659284 9.5442 4.36989e-07 9.37495 4.22194e-07Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  )}
                </button>
              </div>
            )
          })}
        </div>
        {sortedList.length > 0 ? (
          sortedList.map((pair, index) => {
            return (
              <div
                key={`token-${index}`}
                className={cn(
                  index > 0 ? 'lg:pt-7 pt-4 ' : 'lg:pt-5 pt-4',
                  'lg:bg-transparent bg-background rounded-[15px] pb-4 lg:pb-0 lg:my-0 my-3 ',
                  colorMode === 'dark' ? '' : 'md-card-shadow',
                  'flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between px-4 lg:px-0',
                )}
              >
                <div className='flex items-center space-x-3 w-full lg:w-[30%]'>
                  {/* <button
                    onClick={() => {
                      const tokenIndex = bookmarkPairs.indexOf(pair.id)
                      if (tokenIndex === -1) {
                        addBookmarkPair(pair.id)
                      } else {
                        removeBookmarkPair(pair.id)
                      }
                    }}
                    className={`${bookmarkPairs.indexOf(pair.id) > -1 ? 'text-[#F1D97A]' : 'text-transparent'}`}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='icon icon-tabler icon-tabler-star-filled'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      strokeWidth={2}
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path
                        d='M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z'
                        strokeWidth={1}
                        stroke={`${colorMode === 'dark' ? `${bookmarkPairs.indexOf(pair.id) > -1 ? '#F1D97A' : '#EAECE8'}` : '#2B2B2B'}`}
                        fill='currentColor'
                      />
                    </svg>
                  </button> */}
                  <div
                    className='flex items-center space-x-1.5 cursor-pointer'
                    onClick={() => {
                      navigate(`/analytics/${version}/pair/${pair.id}`)
                    }}
                  >
                    <div className='flex items-cener space-x-2'>
                      <div className='flex items-center -space-x-4'>
                        <img className='w-[30px] h-[30px] z-20' alt='' src={pair.token0.logoURI} />
                        <img className='w-[30px] h-[30px] z-10' alt='' src={pair.token1.logoURI} />
                      </div>
                      <div className='flex items-center'>
                        <p className='text-[17px] leading-5 font-medium'>
                          {pair.token0.symbol}/{pair.token1.symbol}
                        </p>
                      </div>
                    </div>
                    {pair.fee && <div className='py-1 px-2 rounded-[5px] bg-grayBackground leading-4 text-sm'>{Number(pair.fee) / 10000}% Fee</div>}
                  </div>
                </div>
                {renderData(pair, index)}
              </div>
            )
          })
        ) : (
          <div className='lg:pt-[38.5px] lg:pb-3 lg:flex lg:items-center lg:justify-center lg:w-full lg:text-[22px] lg:leading-7 lg:font-medium flex items-center justify-center h-full w-full text-[22px] leading-7 font-medium'>
            No Results
          </div>
        )}
      </div>
      {pairsData.length > 10 && (
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          currentPage={currentPage}
          total={pairsData.length}
        />
      )}
    </>
  )
}

export default PairsTable
