import { forwardRef } from 'react'
import { Icon } from '@chakra-ui/react'

const RightPlayArrowIcon = forwardRef((props, ref) => (
  <Icon width='6px' height='10px' viewBox='0 0 6 10' fill='none' ref={ref} {...props}>
    <path
      d='M4.49515e-07 0.625047L6.70464e-08 9.37492C5.96471e-08 9.54419 0.065928 9.69073 0.197894 9.81437C0.329823 9.93795 0.486051 10 0.666614 10C0.847177 10 1.00333 9.93799 1.13544 9.81437L5.80211 5.43942C5.93389 5.31581 6 5.16938 6 5C6 4.83062 5.93389 4.68419 5.80211 4.56048L1.13544 0.185523C1.00348 0.0618067 0.847178 -2.25237e-07 0.666615 -2.3313e-07C0.486087 -2.41021e-07 0.329823 0.0618067 0.197894 0.185523C0.0659284 0.309376 4.56913e-07 0.455805 4.49515e-07 0.625047Z'
      fill='currentColor'
    />
  </Icon>
))

RightPlayArrowIcon.displayName = 'RightPlayArrowIcon'

export default RightPlayArrowIcon
