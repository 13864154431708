import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { defineStyle, Box } from '@chakra-ui/react'
import { NumberOfRows } from '../../../config/constants'
import DownPlayArrowIcon from '../../icons/down-play-arrow'
import { cn } from '../../../helpers/tailwind'

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: 'active', // default to "selected"
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  /* color: #fff; */
  font-size: 17px;
  li a {
    padding: 5px 11px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .previous,
  .next {
    a {
      font-size: 30px;
      line-height: 1;
    }
  }
  li.active a {
    border-radius: 3px;
    border-width: 1px;
  }
  li.disabled a {
    color: var(--gray-text);
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`

const paginationStyle = defineStyle({
  'li.active': {
    a: {
      _light: {
        backgroundColor: '#7AF182',
        borderColor: '#000000',
      },
      _dark: {
        backgroundColor: '#7af1821a',
        borderColor: '#7AF182',
      },
    },
  },
})

const downPlayArrowStyle = defineStyle({
  width: '12px',
  height: 'auto',
})

const Pagination = ({ pageSize, setPageSize, handlePageClick, pageCount, currentPage, total = 0, hideDetail = false }) => {
  const [rowDropDown, setRowDropDown] = useState(false)
  return (
    <div className={cn('flex flex-col-reverse items-center lg:flex-row w-full justify-center mt-[15px] ', !hideDetail ? '' : 'w-auto')}>
      {!hideDetail && (
        <div className='flex space-x-5 lg:space-x-2 mt-3 lg:mt-0'>
          <div className='flex items-center space-x-2.5 text-[17px]'>
            <p>Show: </p>{' '}
            <div className='relative z-20'>
              <div
                onClick={() => {
                  setRowDropDown(!rowDropDown)
                }}
                className='flex items-center space-x-1 cursor-pointer'
              >
                <p>{pageSize} Rows</p>
                <div className={`${rowDropDown ? 'rotate-180' : ''} transform transition-all duration-300 ease-in-out`}>
                  <DownPlayArrowIcon {...downPlayArrowStyle} />
                </div>
              </div>
              {rowDropDown && (
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setRowDropDown(false)
                  }}
                >
                  <div className='bg-background overflow-auto border-primaryBorder border text-xs md:text-base leading-6 rounded-md pl-3 py-2.5 pr-6 absolute top-8'>
                    {NumberOfRows.map((item, idx) => {
                      return (
                        <div
                          onClick={() => {
                            setPageSize(item)
                            setRowDropDown(false)
                          }}
                          className='flex items-center space-x-1 cursor-pointer'
                          key={idx * Math.random()}
                        >
                          <span>{item}</span> <p>Rows</p>
                        </div>
                      )
                    })}
                  </div>
                </OutsideClickHandler>
              )}
            </div>
          </div>
          <div className='flex items-center space-x-2.5 text-[17px]'>
            {`${currentPage * pageSize + 1}-${Math.min(currentPage * pageSize + pageSize, total)} of ${total}`}
          </div>
        </div>
      )}
      <Box sx={{ ...paginationStyle }}>
        <MyPaginate
          breakLabel='...'
          nextLabel='▸'
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel='◂'
          renderOnZeroPageCount={null}
          forcePage={currentPage}
        />
      </Box>
    </div>
  )
}

export default Pagination
