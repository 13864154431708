import React, { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useColorMode } from '@chakra-ui/react'
import { RainbowConnect } from '../../connectWallet/RainbowConnect'
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics'
import './style.scss'
import OutsideClickHandler from 'react-outside-click-handler'
import { connectors, defaultChainId } from '../../../config/constants'
import Transaction from '../Transaction'
import { menuLinks } from './links'
import { useWeb3Wagmi } from '../../../hooks/useWeb3'
// import { showBannerKey } from '../../../config/constants'
import ThemeToggler from '../ThemeToggler'

const links = menuLinks[defaultChainId]

const MobileMenu = ({ item, idx, route }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <li key={idx} className={`links`}>
      {item.dropdown ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsOpen(false)
          }}
        >
          <div className='relative'>
            <div
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              className='flex items-center space-x-1 cursor-pointer relative z-10 text-white'
            >
              <span>{item.name}</span>
              <img
                alt='dropdown'
                src='/images/header/dropdown-arrow.svg'
                className={`${!isOpen ? 'rotate-180' : 'rotate-0'} transition-all duration-150 ease-in-out`}
              />
            </div>
            {isOpen && (
              <div className='p-3 w-[160px] absolute top-10 bg-[#252525] -left-[50px] z-40 rounded-[20px] flex flex-col text-white text-[17px] leading-9'>
                {item.link.map((_item, j) => {
                  return _item.external ? (
                    <div
                      className='doc-link'
                      id={'test'}
                      key={`subitem-${j}`}
                      onClick={() => {
                        window.open(_item.link, '_blank')
                        setIsOpen(false)
                      }}
                    >
                      {_item.name}
                    </div>
                  ) : (
                    <Link
                      key={j}
                      onClick={() => {
                        setIsOpen(false)
                      }}
                      className={`${route.pathname === _item.link && 'text-sky'} no-link text-white`}
                      to={_item.link}
                    >
                      {_item.name}
                    </Link>
                  )
                })}
              </div>
            )}
          </div>
        </OutsideClickHandler>
      ) : (
        <Link className={`${route.pathname === item.link && 'text-sky'} no-link`} to={item.link}>
          {item.name}
        </Link>
      )}
    </li>
  )
}

const Header = () => {
  useGoogleAnalytics()
  const route = useLocation()
  const { colorMode } = useColorMode()
  const [open, setOpen] = useState(false)
  const [connector, setConnector] = useState(null)
  const { account } = useWeb3Wagmi()
  const [selected, setSelected] = useState(false)
  // const [showBanner, setShowBanner] = useState(bannerTitle && window.localStorage.getItem(showBannerKey) === 'true')

  // useEffect(() => {
  //   if (!window.localStorage.getItem(showBannerKey) && (bannerVisible || showSurge)) {
  //     window.localStorage.setItem(showBannerKey, 'true')
  //     setShowBanner(true)
  //   }
  // }, [])

  useEffect(() => {
    setOpen(false)
  }, [route.pathname])

  useEffect(() => {
    if (account) {
      if (!connector && !selected) {
        setConnector(connectors[0])
        setSelected(false)
      }
    } else {
      setConnector(null)
    }
  }, [account, connector, selected, setSelected])

  // const handleClose = () => {
  //   window.localStorage.setItem(showBannerKey, 'false')
  //   setShowBanner(false)
  // }

  return (
    <>
      <div className={`header-wrap fixed w-full z-[120]`}>
        {/* {bannerVisible && showBanner && (
          <div className='banner justify-between items-center z-500'>
            <div className='flex flex-col md:flex-row w-full justify-center items-center text-xs md:text-base'>
              <p className='font-bold md:mr-4 whitespace-nowrap'>{bannerTitle}</p>
              <div className='font-medium flex flex-row flex-wrap justify-center'>
                <Link to={bannerLink} className='underline underline-offset-2'>
                  <p className='mr-0 md:mr-2'>{bannerText}</p>
                </Link>
              </div>
            </div>
            <img onClick={() => handleClose()} src='/images/common/close-button2.svg' alt='' className='cursor-pointer w-6 h-6 ml-6' />
          </div>
        )}
        {showSurge && showBanner && (
          <div className='banner justify-between items-center z-500'>
            <div className='flex flex-col md:flex-row w-full justify-center items-center text-xs md:text-sm'>
              <p className='md:mr-4 text-center mb-2 md:mb-0'>
                Lynex joins <span className='font-bold'>Linea's SURGE program</span>. Add liquidity, earn <span className='font-bold'>LXP-L</span> points.
              </p>
              <div className='font-medium flex flex-row flex-wrap justify-center'>
                <Link to={'https://referrals.linea.build/?refCode=oGi7YJuUKT'} target='_blank' className='underline underline-offset-2'>
                  <p className='mr-0 md:mr-2'>Learn More</p>
                </Link>
              </div>
            </div>
            <img onClick={() => handleClose()} src='/images/common/close-button2.svg' alt='' className='cursor-pointer w-6 h-6 ml-6' />
          </div>
        )} */}
        <div className={`bg-background transition-all duration-300 ease-in-out border-t-[1px] border-b-[1px] border-[#12121266]`}>
          <div className='header px-4 2xl:px-12 py-5'>
            <Link to='/'>
              <img className='w-[125px] h-auto relative z-10' alt='logo' src={`/images/header/${colorMode}-logo.svg`} />
            </Link>
            <div>
              {/* <div>Dark Mode</div> */}
              <ThemeToggler />
            </div>
            {/* <ul className='navigation absolute z-20 justify-center hidden xl:flex items-center w-[calc(100vw-120px)]'>
              {links.map((item, idx) => {
                return <Menu item={item} key={`main-${idx}`} idx={idx} />
              })}
            </ul> */}
            {/* <div
              className={`tracking-[2px] xl:px-[25px] xl:tracking-[2px] items-center font-semibold   text-sm md:text-sm relative z-20  xl:text-base  mobile-btn f-f-fg hidden xl:flex`}
            >
              <div className='line1' />
              <div className='line2' />
              <RainbowConnect />
            </div> */}
            {/* <button
              onClick={() => {
                setOpen(true)
              }}
              className='bg-transparent w-8 xl:hidden'
            >
              <img alt='' src='/images/header/hamburger-menu.png' />
            </button> */}
          </div>
        </div>
      </div>

      {/* mobile flow */}
      <div className={`top-bg !z-[1000] xl:hidden ${open ? 'top-0' : 'top-minus'}`}>
        <div className='inner-tab'>
          <div className='top-navigation'>
            <Link to='/'>
              <img className='logo-2' alt='' src={`/images/header/${colorMode}-logo.svg`} />
            </Link>
            <div
              onClick={() => {
                setOpen(false)
              }}
              className='closeButton'
            >
              <img alt='' src='/images/common/close-button2.svg' />
            </div>
          </div>
          <div className='bottom-navigation w-full'>
            <ul className='flex flex-col items-center justify-center'>
              {links.map((item, idx) => {
                return <MobileMenu item={item} idx={idx} route={route} key={`mobile-${idx}`} />
              })}
            </ul>
            {
              <div className='mobile-btn f-f-fg'>
                <div className='line1' />
                <div className='line2' />
                <RainbowConnect className={'mt-10'} accountStatus={'address'} chainStatus={'none'} label='CONNECT WALLET' />
              </div>
            }
          </div>
        </div>
        {/* <img className='bg-statue' alt='' src='/images/header/top-bg.png' /> */}
      </div>
      <Transaction />
      {/* <div className='cursor-pointer' onClick={() => setTheme()}>{`${getThemeStatus()} Mode`}</div> */}
    </>
  )
}

export default Header
