import React from 'react'
import Overview from '../../components/pages/analytics/overview'
import AnalyticsHeader from '../../components/pages/analytics/analyticsHeader'

const Index = () => {
  return (
    <>
      <div className='w-full'>
        <div className='max-w-[1104px] mx-auto w-full'>
          <AnalyticsHeader />
          <Overview />
        </div>
      </div>
    </>
  )
}

export default Index
