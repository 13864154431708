import { forwardRef } from 'react'
import { Icon } from '@chakra-ui/react'

const DownPlayArrowIcon = forwardRef((props, ref) => (
  <Icon width='10px' height='6px' viewBox='0 0 10 6' fill='none' ref={ref} {...props}>
    <path
      d='M9.37495 4.22194e-07L0.625082 -3.42744e-07C0.455805 -3.57543e-07 0.309275 0.0659276 0.185627 0.197893C0.0620475 0.329822 4.82045e-07 0.48605 4.66259e-07 0.666614C4.50474e-07 0.847177 0.0620131 1.00333 0.185627 1.13544L4.56058 5.80211C4.68419 5.93389 4.83062 6 5 6C5.16938 6 5.31581 5.93389 5.43952 5.80211L9.81448 1.13544C9.93819 1.00348 10 0.847178 10 0.666615C10 0.486087 9.93819 0.329823 9.81448 0.197894C9.69062 0.0659284 9.5442 4.36989e-07 9.37495 4.22194e-07Z'
      fill='currentColor'
    />
  </Icon>
))

DownPlayArrowIcon.displayName = 'DownPlayArrowIcon'

export default DownPlayArrowIcon
