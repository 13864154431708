import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

import { focusVisibleStyles } from './focusVisible'

const solidLightDisabled = {
  bg: 'gray.300',
  color: 'gray.900',
  borderColor: 'gray.900',
}

const outlineLightDisabled = {
  color: 'gray.900',
  borderColor: 'gray.900',
}

const outlineDarkDisabled = {
  borderColor: 'green.300',
}

const themeSolidMap = {
  dark: {
    bg: '#54e15e1a',
    color: '#EAECE8',
    borderColor: '#54E15E',
    _hover: {
      bg: '#54e15e4d',
    },
    _active: {
      bg: '#54e15e4d',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
    },
    _focusVisible: focusVisibleStyles,
  },
  light: {
    bg: '#2B2B2B',
    color: '#EAECE8',
    borderColor: '#2B2B2B',
    _hover: {
      bg: '#7AF182',
      color: '#2B2B2B',
    },
    _active: {
      bg: '#7AF182',
      color: '#2B2B2B',
      borderColor: '#7AF182',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
      ...solidLightDisabled,
      _hover: solidLightDisabled,
      _active: solidLightDisabled,
    },
    _focusVisible: focusVisibleStyles,
  },
}

const themeOutlineMap = {
  dark: {
    bg: 'transparent',
    color: 'gray.50',
    borderColor: 'green.300',
    _hover: {
      bg: 'transparent',
      borderColor: 'brandAccent.300',
    },
    _active: {
      bg: 'green.300',
      color: 'gray.900',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
      ...outlineDarkDisabled,
      _hover: outlineDarkDisabled,
      _active: outlineDarkDisabled,
    },
    _focusVisible: focusVisibleStyles,
  },
  light: {
    bg: 'transparent',
    color: 'gray.900',
    borderColor: 'gray.900',
    _hover: {
      bg: 'transparent',
      borderColor: 'green.300',
    },
    _active: {
      bg: 'green.300',
      borderColor: 'green.300',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
      ...outlineLightDisabled,
      _hover: outlineLightDisabled,
      _active: outlineLightDisabled,
    },
    _focusVisible: focusVisibleStyles,
  },
}

const unstyledButton = defineStyle({
  border: 'none',
  borderWidth: '0',
  borderColor: 'transparent',
  alignItems: 'center',
  display: 'flex',
  _focusVisible: focusVisibleStyles,
})

const themeTransparentMap = {
  dark: {
    bg: 'transparent',
    color: 'gray.50',
    borderColor: 'transparent',
    _hover: {
      bg: 'brandAccent.300',
    },
    _active: {
      bg: 'brandAccent.500',
      borderColor: 'green.300',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
    },
    _focusVisible: focusVisibleStyles,
  },
  light: {
    bg: 'transparent',
    color: 'gray.600',
    borderColor: 'transparent',
    _hover: {
      bg: 'green.300',
      color: 'gray.900',
    },
    _active: {
      bg: 'green.300',
      color: 'gray.900',
      borderColor: 'green.300',
      _focus: {
        outline: 'none',
      },
    },
    _disabled: {
      opacity: 0.4,
      ...solidLightDisabled,
      _hover: solidLightDisabled,
      _active: solidLightDisabled,
    },
    _focusVisible: focusVisibleStyles,
  },
}

const themeAccentMap = {
  light: {
    variant: 'solid',
    bg: 'gray.600',
    color: 'brandGray.150',
    borderColor: 'gray.900',
    _hover: {
      bg: 'yellow.200',
      color: 'gray.600',
      _disabled: {
        bg: 'gray.600',
        opacity: 0.4,
        color: 'brandGray.150',
      },
    },
  },
  dark: {
    variant: 'transparent',
    background: 'transparent',
    borderColor: 'brandGray.100',
    color: 'brandGray.100',
    _hover: { color: 'yellow.200', borderColor: 'yellow.200', background: 'transparent' },
    _disabled: {
      _hover: {
        color: 'brandGray.100',
        borderColor: 'brandGray.100',
      },
    },
  },
}

export const buttonLikeVariants = defineStyleConfig({
  variants: {
    outline: ({ colorMode }) => themeOutlineMap[colorMode] || themeOutlineMap.light,
    solid: ({ colorMode }) => themeSolidMap[colorMode] || themeSolidMap.light,
    unstyled: () => unstyledButton,
    transparent: ({ colorMode }) => themeTransparentMap[colorMode] || themeTransparentMap.light,
    accent: ({ colorMode }) => themeAccentMap[colorMode] || themeAccentMap.light,
  },
})
