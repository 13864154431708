import React from 'react'
import { useColorMode } from '@chakra-ui/react'

import { TabSizes } from '../../../config/constants'
import { cn } from '../../../helpers/tailwind'

const TabFilter = ({ data, filter, setFilter, className, size = TabSizes.MEDIUM, wfull }) => {
  const { colorMode } = useColorMode()

  return (
    <div
      className={cn(
        'z-0 flex items-center rounded-[30px] bg-[var(--background)] border-[2px] ',
        wfull ? 'w-full' : 'sm:w-auto w-full',
        colorMode === 'dark' ? ' border-[#EAECE8]' : ' border-primaryBorder',
        className ? ' ' + className : '',
      )}
    >
      {data.map((item, idx) => {
        return (
          <div
            onClick={() => setFilter(item)}
            key={`tab-${idx}`}
            className={cn(
              'z-1 cursor-pointer flex-grow whitespace-nowrap m-[-2px] h-[34px] md:h-10 rounded-[30px] flex items-center justify-center uppercase text-[13px] lg:text-sm xl:text-[15px] border-[2px] transition-all duration-300 ',
              size === TabSizes.LARGE ? 'px-4' : size === TabSizes.MEDIUM ? 'px-3' : 'px-2',
              filter === item
                ? ` bg-secondary font-medium ${colorMode === 'dark' ? 'border-[#F1D97A]' : 'border-primaryBorder'}`
                : ' text-dimGray border border-transparent',
            )}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

export default TabFilter
