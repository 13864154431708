import { forwardRef } from 'react'
import { Icon } from '@chakra-ui/react'

const SearchIcon = forwardRef((props, ref) => (
  <Icon width='16px' height='16px' viewBox='0 0 16 16' fill='none' ref={ref} {...props}>
    <path
      d='M11.6346 11.6128L15 15M13.4444 7.22222C13.4444 8.45286 13.0795 9.65586 12.3958 10.6791C11.7121 11.7023 10.7403 12.4998 9.60336 12.9708C8.4664 13.4417 7.21531 13.565 6.00832 13.3249C4.80133 13.0848 3.69264 12.4922 2.82244 11.622C1.95225 10.7518 1.35964 9.64309 1.11956 8.4361C0.879472 7.22911 1.0027 5.97802 1.47365 4.84106C1.9446 3.7041 2.74211 2.73233 3.76535 2.04863C4.78859 1.36492 5.99158 1 7.22222 1C8.87246 1 10.4551 1.65555 11.622 2.82244C12.7889 3.98933 13.4444 5.57199 13.4444 7.22222Z'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
))

SearchIcon.displayName = 'SearchIcon'

export default SearchIcon
