import React, { useMemo, useState } from 'react'
import { useColorMode } from '@chakra-ui/react'
import Pagination from '../../../common/Pagination'
import { NumberOfRows } from '../../../../config/constants'
// import { useBookmarkTokens } from '../../../../state/application/hooks'
import { formatAmount } from '../../../../utils/formatNumber'
import { useNavigate } from 'react-router-dom'
import PercentChange from '../../../common/PercentChange'
import { cn } from '../../../../helpers/tailwind'

const SORT_FIELD = {
  NAME: 'name',
  LIQ: 'totalLiquidityUSD',
  VOL: 'oneDayVolumeUSD',
  PRICE: 'priceUSD',
  CHANGE: 'priceChangeUSD',
}

const HeaderOptions = [
  {
    label: 'Name',
    sort: SORT_FIELD.NAME,
  },
  {
    label: 'Price',
    sort: SORT_FIELD.PRICE,
  },
  {
    label: '24H Change',
    sort: SORT_FIELD.CHANGE,
  },
  {
    label: '24H Volume',
    sort: SORT_FIELD.VOL,
  },
  {
    label: 'Liquidity',
    sort: SORT_FIELD.LIQ,
  },
]

const TokensTable = ({ tokensData, version, searchText }) => {
  // const { bookmarkTokens, addBookmarkToken, removeBookmarkToken } = useBookmarkTokens()
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.VOL)
  const [pageSize, setPageSize] = useState(NumberOfRows[0])
  const [currentPage, setCurrentPage] = useState(0)
  const navigate = useNavigate()
  const { colorMode } = useColorMode()

  const handlePageClick = (event) => {
    setCurrentPage(event.selected)
  }

  const filteredTokens = useMemo(() => {
    if (!searchText) return tokensData
    return tokensData.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase()) || item.symbol.toLowerCase().includes(searchText.toLowerCase())
    })
  }, [tokensData, searchText])

  const pageCount = useMemo(() => {
    return Math.ceil(filteredTokens.length / pageSize)
  }, [filteredTokens, pageSize])

  const sortedList = useMemo(() => {
    return (
      filteredTokens &&
      filteredTokens
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn]) ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
        })
        .slice(pageSize * currentPage, (currentPage + 1) * pageSize)
    )
  }, [filteredTokens, pageSize, currentPage, sortDirection, sortedColumn])

  const renderData = (data) => {
    return (
      <>
        <div className='w-1/2 lg:w-[15%] mt-[11px] lg:mt-0'>
          <p className='lg:hidden text-sm f-f-fg font-semibold'>{HeaderOptions[1].label}</p>
          <div className='leading-5'>${formatAmount(data.priceUSD)}</div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[11px] lg:mt-0'>
          <p className='lg:hidden text-sm f-f-fg font-semibold'>{HeaderOptions[2].label}</p>
          <div className='leading-5 w-full'>
            <PercentChange value={data.priceOneDayChangeUSD} />
          </div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
          <p className='lg:hidden text-sm f-f-fg font-semibold'>{HeaderOptions[3].label}</p>
          <div className='leading-5'>${formatAmount(data.volumeOneDayUSD)}</div>
        </div>
        <div className='w-1/2 lg:w-[15%] mt-[15px] lg:mt-0'>
          <p className='lg:hidden text-sm f-f-fg font-semibold'>{HeaderOptions[4].label}</p>
          <div className='leading-5'>${formatAmount(data.liquidityUSD)}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={cn('w-full bg-background rounded-[15px] lg:px-6 pb-4 lg:pb-6 pt-4 mt-3.5', colorMode === 'dark' ? '' : 'card-shadow')}>
        <div className='lg:flex justify-between hidden font-medium text-[17px] xl:text-[18px] pb-3.5 border-b border-primaryBorder'>
          {HeaderOptions.map((option, idx) => {
            return (
              <div key={idx} className={`${idx === 0 ? 'w-[30%]' : 'w-[15%]'} flex items-center`}>
                <button
                  onClick={() => {
                    setSortedColumn(option.sort)
                    setSortDirection(sortedColumn !== option.sort ? true : !sortDirection)
                  }}
                  className={`flex items-center space-x-[3px]`}
                >
                  <span>{option.label}</span>
                  {sortedColumn === option.sort && (
                    <div className='flex items-center mt-[2px]'>
                      <svg
                        className={`mr-[3px] text-primaryText ${!sortDirection ? 'rotate-180' : ''} `}
                        xmlns='http://www.w3.org/2000/svg'
                        width={12}
                        viewBox='0 0 10 6'
                      >
                        <path
                          d='M9.37495 4.22194e-07L0.625082 -3.42744e-07C0.455805 -3.57543e-07 0.309275 0.0659276 0.185627 0.197893C0.0620475 0.329822 4.82045e-07 0.48605 4.66259e-07 0.666614C4.50474e-07 0.847177 0.0620131 1.00333 0.185627 1.13544L4.56058 5.80211C4.68419 5.93389 4.83062 6 5 6C5.16938 6 5.31581 5.93389 5.43952 5.80211L9.81448 1.13544C9.93819 1.00348 10 0.847178 10 0.666615C10 0.486087 9.93819 0.329823 9.81448 0.197894C9.69062 0.0659284 9.5442 4.36989e-07 9.37495 4.22194e-07Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  )}
                </button>
              </div>
            )
          })}
        </div>
        {sortedList.length > 0 ? (
          sortedList.map((token, index) => {
            return (
              <div
                className={`${index > 0 ? 'lg:pt-7 pt-[17px]' : 'lg:pt-5'} ${
                  index !== sortedList.length - 1 ? 'border-b lg:border-b-0 border-primaryBorder pb-2.5 lg:pb-0' : 'pb-2.5 lg:pb-0'
                }  flex flex-wrap lg:flex-nowrap items-start lg:items-center w-full justify-between px-4 lg:px-0`}
                key={`token-${index}`}
              >
                <div className='flex items-center space-x-3 w-full lg:w-[30%]'>
                  {/* <button
                    onClick={() => {
                      const tokenIndex = bookmarkTokens.indexOf(token.id)
                      if (tokenIndex === -1) {
                        addBookmarkToken(token.id)
                      } else {
                        removeBookmarkToken(token.id)
                      }
                    }}
                    className={`${bookmarkTokens.indexOf(token.id) > -1 ? 'text-[#F1D97A]' : 'text-transparent'}`}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='icon icon-tabler icon-tabler-star-filled'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      strokeWidth={2}
                      stroke='currentColor'
                      fill='none'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    >
                      <path
                        d='M8.243 7.34l-6.38 .925l-.113 .023a1 1 0 0 0 -.44 1.684l4.622 4.499l-1.09 6.355l-.013 .11a1 1 0 0 0 1.464 .944l5.706 -3l5.693 3l.1 .046a1 1 0 0 0 1.352 -1.1l-1.091 -6.355l4.624 -4.5l.078 -.085a1 1 0 0 0 -.633 -1.62l-6.38 -.926l-2.852 -5.78a1 1 0 0 0 -1.794 0l-2.853 5.78z'
                        strokeWidth={1}
                        stroke={`${colorMode === 'dark' ? `${bookmarkTokens.indexOf(token.id) > -1 ? '#F1D97A' : '#EAECE8'}` : '#2B2B2B'}`}
                        fill='currentColor'
                      />
                    </svg>
                  </button> */}
                  <div
                    className='flex items-cener space-x-2 cursor-pointer'
                    onClick={() => {
                      navigate(`/analytics/${version}/token/${token.id}`)
                    }}
                  >
                    <img className='w-[30px] h-[30px]' alt='' src={token.logoURI} />
                    <div className='flex items-center space-x-1.5'>
                      <p className='text-[17px] leading-[1] font-medium'>{token.name}</p>
                      <p className='text-sm leading-[1] text-grayText'>{token.symbol}</p>
                    </div>
                  </div>
                </div>
                {renderData(token, index)}
              </div>
            )
          })
        ) : (
          <div className='lg:pt-[38.5px] lg:pb-3 lg:flex lg:items-center lg:justify-center lg:w-full lg:text-[22px] lg:leading-7 lg:font-medium flex items-center justify-center h-full w-full text-[22px] leading-7 font-medium'>
            No Results
          </div>
        )}
      </div>
      {tokensData.length > 10 && (
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          currentPage={currentPage}
          total={tokensData.length}
        />
      )}
    </>
  )
}

export default TokensTable
