import React from 'react'
import { useColorMode } from '@chakra-ui/react'
import { formatAmount } from '../../../utils/formatNumber'
import { cn } from '../../../helpers/tailwind'

const PercentChange = ({ value }) => {
  const { colorMode } = useColorMode()

  return (
    <div
      className={cn(
        'px-2 py-1 leading-5 text-[14px] table rounded-[5px] ',
        value > 0
          ? `bg-primary ${colorMode === 'dark' ? 'text-[#7AF182]' : ''}`
          : value < 0
          ? `bg-danger ${colorMode === 'dark' ? 'text-[#F26262]' : ''}`
          : 'bg-grayBackground',
      )}
    >
      {value > 0 ? '+' : value < 0 ? '-' : ''}
      {formatAmount(Math.abs(value))}%
    </div>
  )
}

export default PercentChange
