import { forwardRef } from 'react'
import { Icon } from '@chakra-ui/react'

const LeftPlayArrowIcon = forwardRef((props, ref) => (
  <Icon width='15px' height='15px' viewBox='0 0 15 15' fill='none' ref={ref} {...props}>
    <path
      d='M3.74976 6.59903H14.6997V8.39902H3.74976L8.56958 13.227L7.2998 14.499L0.299805 7.49902L7.2998 0.499023L8.56958 1.77203L3.74976 6.59903Z'
      fill='currentColor'
    />
  </Icon>
))

LeftPlayArrowIcon.displayName = 'LeftPlayArrowIcon'

export default LeftPlayArrowIcon
