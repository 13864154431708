import React, { useState, useEffect } from 'react'
import useRefresh from '../hooks/useRefresh'
import useWeb3, { useWeb3Wagmi } from '../hooks/useWeb3'
import { getBaseAssets, getDexScreenerPrice } from '../utils/api'
import { fetchUserAssetsDataAsync } from '../utils/fetchUserAssets'
import { useImportTokens } from '../state/application/hooks'
import { tokenAddresses } from '../config/tokens/tokenlists'
import { scSanityCheckEnabled } from '../config/constants'

const BaseAssetsConetext = React.createContext([])

const BaseAssetsConetextProvider = ({ children }) => {
  const [baseAssets, setBaseAssets] = useState([])
  const { fastRefresh } = useRefresh()
  const web3 = useWeb3()
  const { account } = useWeb3Wagmi()
  const { importedTokens } = useImportTokens()

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        let assets = await getBaseAssets()
        if (scSanityCheckEnabled) {
          /// @note security measure to make sure we only list tokens from the tokenlist
          const filtered = assets.filter((asset) => tokenAddresses.includes(asset.address.toLowerCase()) || asset.address === 'ETH')
          assets = filtered
        }
        if (importedTokens.length > 0) {
          const importedTokensWithPrice = await Promise.all(
            importedTokens.map(async (token) => {
              const price = await getDexScreenerPrice(token.address.toLowerCase())
              return { ...token, price }
            }),
          )
          assets = assets.concat(importedTokensWithPrice)
        }
        if (assets) {
          if (web3 && account) {
            try {
              const data = await fetchUserAssetsDataAsync(web3, assets, account)
              const sortedData = data.sort((a, b) => {
                if (a.balance.times(a.price).lt(b.balance.times(b.price))) return 1
                if (a.balance.times(a.price).gt(b.balance.times(b.price))) return -1
              })
              setBaseAssets(sortedData)
            } catch (e) {
              console.error('User Assets fetch had error', e)
              setBaseAssets(assets)
            }
          } else {
            setBaseAssets(assets)
          }
        }
      } catch (error) {
        console.error('Base Assets fetched had error', error)
      }
    }
    fetchInfo()
  }, [fastRefresh, web3, account, importedTokens])

  return <BaseAssetsConetext.Provider value={baseAssets}>{children}</BaseAssetsConetext.Provider>
}

export { BaseAssetsConetext, BaseAssetsConetextProvider }
