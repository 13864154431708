import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect } from 'react'
import { fusionClient } from '../apollo/client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { getPercentChange, getBlockFromTimestamp } from '../utils/analyticsHelper'
import { ETH_PRICE } from '../apollo/queries'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { getChartDataTotal, getGlobalDataFusion } from '../utils/fusionGraph'

const UPDATE_GLOBAL = 'UPDATE'
const UPDATE_CHART = 'UPDATE_CHART'
const UPDATE_ETH_PRICE = 'UPDATE_ETH_PRICE'
const ETH_PRICE_KEY = 'ETH_PRICE_KEY'

// format dayjs with the libraries that we need
dayjs.extend(utc)
dayjs.extend(weekOfYear)

const GlobalDataContext = createContext()

function useGlobalDataContext() {
  return useContext(GlobalDataContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_GLOBAL: {
      const { data } = payload
      return {
        ...state,
        globalData: data,
      }
    }

    case UPDATE_CHART: {
      const { data } = payload
      return {
        ...state,
        chartData: data,
      }
    }
    case UPDATE_ETH_PRICE: {
      const { ethPrice, oneDayPrice, ethPriceChange } = payload
      return {
        [ETH_PRICE_KEY]: ethPrice,
        oneDayPrice,
        ethPriceChange,
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})
  const update = useCallback((data) => {
    dispatch({
      type: UPDATE_GLOBAL,
      payload: {
        data,
      },
    })
  }, [])

  const updateChart = useCallback((data) => {
    dispatch({
      type: UPDATE_CHART,
      payload: {
        data,
      },
    })
  }, [])

  const updateEthPrice = useCallback((ethPrice, oneDayPrice, ethPriceChange) => {
    dispatch({
      type: UPDATE_ETH_PRICE,
      payload: {
        ethPrice,
        oneDayPrice,
        ethPriceChange,
      },
    })
  }, [])
  return (
    <GlobalDataContext.Provider
      value={useMemo(
        () => [
          state,
          {
            update,
            updateChart,
            updateEthPrice,
          },
        ],
        [state, update, updateChart, updateEthPrice],
      )}
    >
      {children}
    </GlobalDataContext.Provider>
  )
}

/**
 * Gets the current price  of ETH, 24 hour price, and % change between them
 */
const getEthPrice = async () => {
  const utcCurrentTime = dayjs()
  const utcOneDayBack = utcCurrentTime.subtract(1, 'day').startOf('minute').unix()

  let ethPrice = 0
  let ethPriceOneDay = 0
  let priceChangeETH = 0

  try {
    let oneDayBlock = await getBlockFromTimestamp(utcOneDayBack)
    let result = await fusionClient.query({
      query: ETH_PRICE(),
      fetchPolicy: 'cache-first',
    })
    let resultOneDay = await fusionClient.query({
      query: ETH_PRICE(oneDayBlock),
      fetchPolicy: 'cache-first',
    })
    const currentPrice = result?.data?.bundles[0]?.ethPriceUSD
    const oneDayBackPrice = resultOneDay?.data?.bundles[0]?.ethPriceUSD
    priceChangeETH = getPercentChange(currentPrice, oneDayBackPrice)
    ethPrice = currentPrice
    ethPriceOneDay = oneDayBackPrice
  } catch (e) {
    console.log(e)
  }
  return [ethPrice, ethPriceOneDay, priceChangeETH]
}

/**
 * Hook that fetches overview data, plus all tokens and pairs for search
 */
export function useGlobalData() {
  const [state, { update }] = useGlobalDataContext()

  const data = useMemo(() => {
    return state?.globalData && state?.globalData
  }, [state])

  // const combinedVolume = useTokenDataCombined(offsetVolumes)

  useEffect(() => {
    async function fetchData() {
      let globalData = await getGlobalDataFusion()
      globalData && update(globalData)
    }
    if (!data) {
      fetchData()
    }
  }, [update, data])

  return data || {}
}

export function useGlobalChartData(version) {
  const [state, { updateChart }] = useGlobalDataContext()

  const data = state?.chartData?.[version]

  /**
   * Fetch data if none fetched or older data is needed
   */
  useEffect(() => {
    async function fetchData() {
      // historical stuff for chart
      let data = await getChartDataTotal()
      updateChart(data)
    }
    if (!data) {
      fetchData()
    }
  }, [data, updateChart])

  return data
}

export function useEthPrice() {
  const [state, { updateEthPrice }] = useGlobalDataContext()
  const ethPrice = state?.[ETH_PRICE_KEY]
  const ethPriceOld = state?.['oneDayPrice']
  useEffect(() => {
    async function checkForEthPrice() {
      if (!ethPrice) {
        let [newPrice, oneDayPrice, priceChange] = await getEthPrice()
        updateEthPrice(newPrice, oneDayPrice, priceChange)
      }
    }
    checkForEthPrice()
  }, [ethPrice, updateEthPrice])

  return [ethPrice, ethPriceOld]
}

export default Provider
