import { ConnectorNames } from '../../utils/connectors'
import { ChainId } from './v3/misc'

const connectorLocalStorageKey = 'lynex-local-key'
const showBannerKey = 'showBanner'
// eslint-disable-next-line no-use-before-define
const defaultChainId = Number(process.env.REACT_APP_CHAIN_ID)
const showV1Analytics = process.env.REACT_APP_V1_SHOW

const REACT_APP_MUON_API_URL = 'https://dibs-shield.muon.net/'

const TransactionType = {
  START: 'start',
  WAITING: 'waiting',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const LotteryStatus = {
  UNKNOWN: 0,
  WON: 1,
  LOST: 2,
}

const connectors = [
  {
    key: 'metamask',
    logo: '/images/wallets/metamask-logo.svg',
    title: 'MetaMask',
    connector: ConnectorNames.MetaMask,
  },
  {
    key: 'trustwallet',
    logo: '/images/wallets/trustwallet-logo.svg',
    title: 'Trust Wallet',
    connector: ConnectorNames.TrustWallet,
  },
  {
    key: 'walletConnect',
    logo: '/images/wallets/walletconnect-logo.svg',
    title: 'Wallet Connect',
    connector: ConnectorNames.WalletConnect,
  },
  {
    key: 'coinbase',
    logo: '/images/wallets/coinbase-wallet-logo.svg',
    title: 'Coinbase Wallet',
    connector: ConnectorNames.Coinbase,
  },
  {
    key: 'bitget',
    logo: '/images/wallets/bitget-wallet-logo.svg',
    title: 'Bitget Wallet',
    connector: ConnectorNames.Bitget,
  },
  // {
  //   key: 'binance',
  //   logo: '/images/wallets/binance-wallet-logo.svg',
  //   title: 'Binance Wallet',
  //   connector: ConnectorNames.BinanceChainWallet,
  // },
  // {
  //   key: 'coin98',
  //   logo: '/images/wallets/coin98-wallet-logo.svg',
  //   title: 'Coin98 Wallet',
  //   connector: ConnectorNames.Coin98Wallet,
  // },
]

const routeAssets = {
  [ChainId.LINEA_MAINNET]: [
    {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
    },
    {
      name: 'Lynex Token',
      symbol: 'LYNX',
      address: '0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 18,
      logoURI: 'https://app.lynex.fi/logo.png',
    },
    {
      name: 'USDC',
      symbol: 'USDC',
      address: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 6,
    },
    {
      name: 'USDT',
      symbol: 'USDT',
      address: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 6,
    },
    {
      name: 'Overnight USD+',
      symbol: 'USD+',
      address: '0xb79dd08ea68a908a97220c76d19a6aa9cbde4376',
      chainId: ChainId.LINEA_MAINNET,
      decimals: 6,
    },
  ],
  [ChainId.BSC]: [
    {
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/WBNB.png',
    },
    {
      name: 'BUSD Token',
      symbol: 'BUSD',
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/BUSD.png',
    },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/USDC.png',
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/USDT.png',
    },
    {
      name: 'Frax',
      symbol: 'FRAX',
      address: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/FRAX.png',
    },
    {
      name: 'Liquid Staking BNB',
      symbol: 'BNBx',
      address: '0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/BNBx.png',
    },
    {
      name: 'Coin98 Dollar',
      symbol: 'CUSD',
      address: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/CUSD.png',
    },
    {
      name: 'Hay Destablecoin',
      symbol: 'HAY',
      address: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/HAY.png',
    },
    {
      name: 'USD+',
      symbol: 'USD+',
      address: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
      chainId: 56,
      decimals: 6,
      logoURI: 'https://thena.fi/images/tokens/USD+.png',
    },
    {
      name: 'Staked BNB',
      symbol: 'stkBNB',
      address: '0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/stkBNB.png',
    },
    {
      name: 'Ankr Reward Bearing BNB',
      symbol: 'ankrBNB',
      address: '0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/ankrBNB.png',
    },
    {
      name: 'THENA',
      symbol: 'THE',
      address: '0xF4C8E32EaDEC4BFe97E0F595AdD0f4450a863a11',
      chainId: 56,
      decimals: 18,
      logoURI: 'https://thena.fi/logo.png',
    },
  ],
  [ChainId.BSC_TESTNET]: [
    {
      name: 'Wrapped BNB',
      symbol: 'WBNB',
      address: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
      chainId: 97,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/WBNB.png',
    },
    {
      name: 'Lynex Token',
      symbol: 'LYNX',
      address: '0x7A286Db4E11F6ED110a15aCb3412E0b838aF7E0A',
      chainId: 97,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/BUSD.png',
    },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0x910F3d5088cc7C6aA72Cf4Faa2d4A5608E76Bf2B',
      chainId: 97,
      decimals: 8,
      logoURI: 'https://thena.fi/images/tokens/USDC.png',
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0x2F1502664c6e89723160b39BF4136e58b9ecF3e9',
      chainId: 97,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/USDT.png',
    },
  ],
  [ChainId.LINEA_TESTNET]: [
    {
      name: 'Wrapped ETH',
      symbol: 'WETH',
      address: '0x2C1b868d6596a18e32E61B901E4060C872647b6C',
      chainId: 59140,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/WBNB.png',
    },
    {
      name: 'Lynex Token',
      symbol: 'LYNX',
      address: '0xCa1B6EB5B97b61813B3444DE45Be5897d8Cd6d9E',
      chainId: 59140,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/BUSD.png',
    },
    {
      name: 'USD Coin',
      symbol: 'USDC',
      address: '0xC0f8eE736cEC8c1a12e9c25272e6f417510165FF',
      chainId: 59140,
      decimals: 8,
      logoURI: 'https://thena.fi/images/tokens/USDC.png',
    },
    {
      name: 'USD Coin Faucet',
      symbol: 'USDC.f',
      address: '0xf56dc6695cF1f5c364eDEbC7Dc7077ac9B586068',
      chainId: 59140,
      decimals: 6,
      logoURI: 'https://thena.fi/images/tokens/USDC.png',
    },
    {
      name: 'Tether USD',
      symbol: 'USDT',
      address: '0x0DFc6bb7eEE496CD789B60255F7B2982F8069415',
      chainId: 59140,
      decimals: 18,
      logoURI: 'https://thena.fi/images/tokens/USDT.png',
    },
  ],
}
const periodLevels = [
  {
    value: 0,
    label: '2 weeks',
  },
  {
    value: 1,
    label: '6 months',
  },
  {
    value: 2,
    label: '1 year',
  },
  {
    value: 3,
    label: 'MAX',
  },
]

const NumberOfRows = [10, 20, 30, 40]

export const PresaleLynxPrice = 0.06

const PoolTypes = {
  ALL: 'ALL',
  STABLE: 'STABLE',
  CORRELATED: 'CORRELATED',
  V1: 'CLASSIC',
  FUSION: 'FUSION',
}

export const POOL_FILTERS = {
  ALL: 'ALL',
  STABLE: 'STABLE',
  CORRELATED: 'CORRELATED',
  V1: 'V1',
  FUSION: 'FUSION',
}

const TabSizes = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
}

const ReferralTabs = ['code', 'rewards', 'leaderboard', 'lottery']
const TaxAssets = [
  '0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979', // fBOMB
  '0x5ba62cbdab03ac70652293e5f5f8afc919cffd78', // FLY
]

const STABLE_TOKENS = {
  BUSD: '0x7d43AABC515C356145049227CeE54B608342c0ad',
  USDT: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
  USDC: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
  DAI: '0x4af15ec2a0bd43db75dd04e62faa3b8ef36b00d5',
  WDAI: '0x023617babed6cef5da825bea8363a5a9862e120f',
  DEI: '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
  USD: '0xe80772eaf6e2e18b651f160bc9158b2a5cafca65',
  ETS: '0x5B852898CD47d2Be1d77D30377b3642290f5Ec75',
  HAY: '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
  FRAX: '0x90c97f71e18723b0cf0dfa30ee176ab653e89f40',
  CUSD: '0xFa4BA88Cf97e282c505BEa095297786c16070129',
  MAI: '0xf3B001D64C656e30a62fbaacA003B1336b4ce12A',
  DOLA: '0x2F29Bc0FFAF9bff337b31CBe6CB5Fb3bf12e5840',
  wUSDR: '0x2952beb1326acCbB5243725bd4Da2fC937BCa087',
  DUSD: '0xa88b54e6b76fb97cdb8ecae868f1458e18a953f4',
  EURO3: '0x3f817b28da4940f018c6b5c0a11c555ebb1264f9',
  USDON: '0xb79dd08ea68a908a97220c76d19a6aa9cbde4376',
  USDTON: '0x1e1f509963a6d33e169d9497b11c7dbfe73b7f13',
  EUSD: '0xBA2f9e7ae9F5f03FCe7D560f986743659e768bbF',
  USDE: '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
  sUSDE: '0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2',
  'USD+': '0xb79dd08ea68a908a97220c76d19a6aa9cbde4376',
}

export const STABLE_FEE = 0.0001
export const VOLATILE_FEE = 0.0025

export const TXN_TYPE = {
  ALL: 'All',
  SWAP: 'Swaps',
  ADD: 'Adds',
  REMOVE: 'Removes',
}

export const AnalyticVersions = {
  v1: 'v1',
  fusion: 'fusion',
  total: 'total',
}

export const AnalyticChart = {
  ONE_WEEK_CHART: 1,
  ONE_MONTH_CHART: 2,
  THREE_MONTH_CHART: 3,
  SIX_MONTH_CHART: 4,
  ONE_YEAR_CHART: 5,
  ALL_CHART: 6,
  CHART_COUNT: 60, //limit analytics chart items not more than 60
}

export const timeframeOptions = {
  WEEK: '1W',
  MONTH: '1M',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  // HALF_YEAR: '6 months',
  ALL_TIME: 'All',
}

export const TRACKED_OVERRIDES_PAIRS = []
export const TRACKED_OVERRIDES_TOKENS = []

export const scSanityCheckEnabled = process.env.REACT_APP_SC_SANITY_CHECK == 1

export const poolBlacklist = ['0x8dabf94c7bdd771e448a4ae4794cd71f9f3d7a0d']

export const gaugeBlacklist = []

export {
  connectorLocalStorageKey,
  showBannerKey,
  defaultChainId,
  connectors,
  TransactionType,
  routeAssets,
  periodLevels,
  NumberOfRows,
  PoolTypes,
  LotteryStatus,
  REACT_APP_MUON_API_URL,
  TaxAssets,
  STABLE_TOKENS,
  ReferralTabs,
  TabSizes,
  showV1Analytics,
}
